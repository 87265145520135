import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/my-course'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/SignIn.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/my-course',
    name: 'myCourse',
    component: () => import('../views/MyCourse/MyCourse.vue'),
    meta: {
      title: '我的课程'
    }
  },
  {
    path: '/live/:type/:id/:chapterId?',
    name: 'live',
    component: () => import('../views/Live/LiveBroadcast.vue')
  },
  {
    path: '/timetable',
    name: 'timetable',
    component: () => import('../views/MyCourse/TimeTable.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string || '';
  const token = window.localStorage.getItem("token");
  if (!token && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
})
export default router